<template>
  <div
    v-if="packages.length > 0"
  >
    <hr>
    <div id="packageForm">
      <h2 class="pricing_header">
        {{ $t('formPackage.packagePricing') }}
        <CurrencyDropdown
          :chargeablecurrencies="chargeableCurrencies"
          @currency-emit="updateCurrency"
        />
      </h2>

      <br>

      <div class="package_purchasing_form">
        <div class="heading">
          <label class="occupancy-desc">
            {{ $t('formPackage.chooseYourPackage') }}
          </label>
        </div>

        <br>

        <PackageInputFields
          :currency="currency"
          :rate="rate"
          :packages="packages"
          @qty-emit="updatePackageQty"
          @package-emit="updateSelectedPackage"
        />

        <HotelInputFields
          :pack="selectedPackage"
          :package-qty="packageQty"
          @qty-emit="updateHotelQty"
          @hotel-emit="updateSelectedHotel"
        />

        <div class="new_order_item">
          <AddToCart
            :form-btn-text="formBtnText"
            :qtyhotel="hotelQty"
            :qtypackage="packageQty"
            :idhotel="idHotel"
            :idpackage="idPackage"
            :idpackageoption="idPackageOption"
            :idstay="idStay"
          />

          <PriceInfo
            :currency="currency"
            :hotel="selectedHotel"
            :hotel-qty="hotelQty"
            :pack="selectedPackage"
            :package-qty="packageQty"
            :rate="rate"
            :stay="selectedStay"
          />
        </div>

        <span :class="isMotoGP() ? 'tax_disclaimer' : 'tax_disclaimer small'">
          {{ $t('taxDisclaimer') }}
        </span>

        <CurrencyDisclaimer
          :currency="currency"
          :package-currency="defaultPackageCurrency"
          :chargeablecurrencies="chargeableCurrencies"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyDropdown,
  CurrencyDisclaimer } from '../../../default/currency_convert'
import AddToCart from './add-to-cart'
import HotelInputFields from './hotel-input'
import PackageInputFields from './package-input-fields'
import PriceInfo from './price-info'
import { mapMutations } from 'vuex'

export default {
  name: 'AddToCartBottomForm',
  components: {
    AddToCart,
    CurrencyDisclaimer,
    CurrencyDropdown,
    HotelInputFields,
    PackageInputFields,
    PriceInfo
  },

  data() {
    return {
      chargeableCurrencies: [],
      hotelQty: 0,
      packageQty: 1,
      packages: [],
      selectedHotel: null,
      selectedPackage: null,
      selectedStay: null
    }
  },

  computed: {
    formBtnText() {
      if (this.isSportsnet()) {
        return this.$t('formPackage.addToCart').toLowerCase()
      } else if (this.isLcsc()) {
        return this.$t('formPackage.addToBasket')
      } else if (this.isAmex() || this.isWnba()) {
        return this.capitalizeEachWord(this.$t('formPackage.addToCart').toLowerCase())
      } else {
        return this.$t('formPackage.addToCart')
      }
    },

    currency(){
      return this.$store.state.event.formCurrency
    },

    rate(){
      return this.$store.state.event.formRate
    },

    idHotel() {
      if (this.selectedHotel) {
        return this.selectedHotel.id
      } else {
        return null
      }
    },

    idPackage() {
      if (this.selectedPackage) {
        return this.selectedPackage.id
      } else {
        return null
      }
    },
    idPackageOption() {
      if (this.selectedPackage) {
        return this.selectedPackage.package_option_id
      } else {
        return null
      }
    },
    idStay() {
      if (this.selectedStay) {
        return this.selectedStay.id
      } else {
        return null
      }
    },
    defaultPackageCurrency(){
      return this.packages && this.packages[0] && this.packages[0].default_currency
    },
    order(){
      return this.$store.state.order.order
    }
  },

  watch: {
    order(){
      if(this.currency != this.order.currency){
        this.$store.state.event.formCurrency = this.order.currency
      }
    }
  },

  created() {
    let packageDiv = document.getElementById('packageCartFormData')

    if (packageDiv) {
      this.updateStatus(packageDiv.dataset.status)
      this.packages = JSON.parse(packageDiv.dataset.packages)
      if(this.packages.find(p => p.purchasable_package_options.length>0)){
        this.packages = this.packages.map(p => {
          return p.purchasable_package_options.map (po => {
            return {...p, ...{display_name: po.display_name_with_default, package_option_id: po.id,
              inventory_on_site: Math.min(po.inventory, p.inventory_on_site)}}
          })
        }).flat()
      }

      this.chargeableCurrencies = this.packages[0].chargeable_currencies
    }
  },

  methods: {
    ...mapMutations('event', ['updateStatus']),
    updateCurrency(errors, currency, rate) {
      if (currency && rate) {
        this.$store.state.event.formCurrency = currency
        this.$store.state.event.formRate = rate
      }
    },

    updateHotelQty(qty) {
      if (qty) { this.hotelQty = qty }
    },

    updatePackageQty(qty) {
      if (qty) { this.packageQty = qty }
    },

    updateSelectedHotel(hotelFields) {
      this.selectedHotel = hotelFields.hotel
      this.selectedStay = hotelFields.stay
    },

    updateSelectedPackage(pack) {
      if (pack) { this.selectedPackage = pack }
    },

    isSportsnet() {
      return window.brand_name == 'Sportsnet'
    },

    isLcsc() {
      return window.brand_name == 'LCSC'
    },

    isAmex() {
      return window.name == 'amex'
    },

    isWnba() {
      return window.name == 'wnba'
    },

    isMotoGP() {
      return window.name == 'motogp'
    },

    capitalizeEachWord(str) {
      let words = str.split(' ')

      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    }
  }
}
</script>
