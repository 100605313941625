<template>
  <select
    id="vue_stay_id"
    v-model="currentStay"
    class="form-group select optional form-control"
    @change="emitStay()"
  >
    <option
      v-for="stay in activeStays"
      :key="stay.id"
      :value="stay"
    >
      {{ stay.room_type_translated }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    selectedStay: {
      default: {},
      required: true
    },

    // eslint-disable-next-line vue/require-prop-types
    stays: {
      default: [],
      required: true
    }
  },

  data() {
    return {
      currentStay: null
    }
  },

  computed: {
    activeStays() {
      return this.stays.filter(stay => stay.active == true)
    }
  },

  watch: {
    stays() {
      this.currentStay = this.stays[0]
      this.emitStay()
    }
  },


  created() {
    this.currentStay = this.stays[0]
    this.emitStay()
  },

  methods: {
    emitStay() {
      this.$emit('stay-change', this.currentStay)
    }
  },
}
</script>
