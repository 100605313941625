<template>
  <div
    class="card-check-boxes"
  >
    <input
      id="terms"
      v-model="acceptedTerms"
      type="checkbox"
      value="true"
      @change="updateTerms"
    >
    <span class="custom-checkbox" />
    <div class="print-link">
      <!-- eslint-disable vue/no-v-html -->
      <label
        for="terms"
        v-html="$t('creditCardForm.acceptedTerms')"
      />

      <!--eslint-enable-->
      <span class="print-btn fa fa-print" />

      <div :class="displayErrorClass">
        {{ displayErrorText }}
      </div>
    </div>

    <input
      id="f1consent"
      v-model="f1Consent"
      type="checkbox"
      class="f1consent"
      value="true"
      @change="$emit('update-f1', f1Consent)"
    >

    <label
      class="f1consent"
      for="f1consent"
    >
      {{ $t('creditCardForm.f1Consent') }}
    </label>

    <button
      v-if="!processing"
      class="continue-button btn btn-primary"
      @click="placeOrder()"
    >
      {{ buttontext }}
    </button>
    <div
      v-if="processing"
      class="processing-payment loading-card"
    >
      <div class="loading-icon" />

      <button
        disabled
        class="continue-button continue-button btn btn-primary"
      >
        {{ buttontext }}
      </button>
    </div>
  <!-- </div> -->
  </div>
</template>

<script>
export default {
  props: {
    displayerror: {
      default: null,
      type: String,
      required: false
    },
    buttontext: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      acceptedTerms: false,
      displayError: true,
      f1Consent: false,
      displayErrorClass: 'hidden',
      processing:false
    }
  },

  computed: {
    displayErrorText() {
      return this.$t('creditCardForm.pleaseAccept')
    },

    disableSubmit() {
      if (this.includechecks == 'true' && this.acceptedTerms == false) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    errs(){
      this.hasErrors()
    }
  },

  methods: {
    updateTerms() {
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
        this.displayErrorClass = 'card-errors'
      } else {
        this.displayError = ''
        this.displayErrorClass = 'hidden'
      }

      this.$emit('update-terms', this.acceptedTerms)
    },
    placeOrder() {
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
        this.displayErrorClass = 'card-errors'
        return
      }
      this.processing = true

      this.$emit('place-order')
    }
  }
}
</script>
