export default {
  props: {
    pack: {},
    packageQty: {
      default: 0,
      required: true,
      type: Number
    }
  },

  data() {
    return {
      displayError: false,
      qty: 1,
      selectedHotel: null,
      selectedStay: null
    }
  },

  computed: {
    hotelInventory() {
      if (this.selectedStay) {
        return this.selectedStay.inventory_on_site
      } else if (this.selectedHotel) {
        return this.selectedHotel.inventory_on_site
      } else {
        return 1
      }
    },

    hotelMinQty() {
      if (this.pack == null || this.selectedHotel == null) { return 1 }

      // Hotel-Only packages should not consider package quantity
      // if (this.pack.price > 0) {
      return Math.max(1, Math.ceil(this.packageQty/3))
      // } else {
      //   return 1
      // }
    },

    hotelMaxQty() {
      if (this.pack == null || this.selectedHotel == null) { return 1 }

      // Hotel-Only packages should not consider package quantity
      if (this.pack.price > 0) {
        return Math.min(this.hotelInventory, 10, this.packageQty)
      } else {
        return Math.min(this.hotelInventory, 10)
      }
    },

    hotelRequired() {
      if (this.pack == null) {
        return false
      } else {
        return this.pack.hotel_required
      }
    },

    hotels() {
      if (this.pack == null) {
        return []
      } else {
        return this.pack.purchasable_hotels
      }
    },

    stays() {
      if (this.selectedHotel == null) {
        return []
      } else {
        let stayIdsSet = new Set()
        return this.selectedHotel.stays.filter(stay => {
          if (!stayIdsSet.has(stay.sf_id) && stay.inventory_on_site > 0 && stay.active) {
            stayIdsSet.add(stay.sf_id)
            return true
          } 
        })
      }
    }
  },

  watch: {
    pack() {
      this.refreshFromNewPackage()
    },

    hotelInventory() {
      this.$store.state.packages.hotelMaxInventory = this.hotelInventory
      console.log('hotelMaxQty', this.$store.state.packages.hotelMaxInventory)
    },

    packageQty() {
      this.resetQty()
    }
  },

  methods: {
    changeQty(qty) {
      this.qty = qty
      this.updateQty()
    },

    changeSelectedHotel(hotel) {
      this.selectedHotel = hotel
      this.selectedStay = this.stays[0] || null
      this.emitSelectedHotel()
    },

    changeSelectedStay(stay) {
      this.selectedStay = stay
      this.emitSelectedHotel()
    },

    emitHotelQty() {
      this.$emit('qty-emit', this.qty)
    },

    emitSelectedHotel() {
      this.$emit('hotel-emit', { hotel: this.selectedHotel, stay: this.selectedStay })
      this.resetQty()
    },

    refreshFromNewPackage() {
      if (this.hotelRequired == true) {
        this.selectedHotel = this.hotels[0]
        this.selectedStay = this.stays[0] || null
      } else {
        this.selectedHotel = null
        this.selectedStay = null
        this.qty = 1
      }
      this.emitHotelQty()
      this.emitSelectedHotel()
    },

    resetQty() {
      if (this.hotelMaxQty < this.qty) {
        this.displayError = true
        this.qty = 1
        this.emitHotelQty()
      } else {
        this.displayError = false
      }
    },

    updateQty() {
      this.emitHotelQty()
      this.displayError = false
    }
  }
}
